import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { SystemActions } from '../actions/system.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';

@Injectable()
export class SystemEffects {
  private readonly actions$: Actions = inject(Actions);
  private readonly router: Router = inject(Router);

  redirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SystemActions.Redirect),
        switchMap(({ url }) => this.router.navigateByUrl(url))
      );
    },
    { dispatch: false }
  );

  redirectToCourtesyPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SystemActions.RedirectToCourtesyPage),
        tap(() => {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href =
            environment.b2cPolicies.redirectCourtesyPageUri;
        })
      );
    },
    { dispatch: false }
  );

  redirectToHomePage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SystemActions.RedirectToHomePage),
        switchMap(() => (window.location.href = 'copilot'))
      );
    },
    { dispatch: false }
  );

  openPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SystemActions.OpenPage),
        tap(({ url, target }) => window.open(url, target))
      );
    },
    { dispatch: false }
  );
}
