import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { TENANT_CONFIG } from '../../../environments/config';
import { environment } from '../../../environments/environment';
import { MODULES } from '../../../environments/module.config';
import { VERSION } from '../../../environments/version';
import { TimeWindow } from '../../models/time-window.model';
import { STORAGE_KEYS, StorageService } from '../storage/storage.service';
import { UserService } from '../user/user.service';

import moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';
import {
  fromEvent as observableFromEvent,
  interval,
  Observable,
  of,
  Subject,
} from 'rxjs';

export const enum MessageType {
  Info,
  Error,
}

@Injectable({
  providedIn: 'root',
})
export class CubeService {
  changeCubeMenu$: Subject<any> = new Subject();
  colorSeed = Date.now();
  offlineStatus: Observable<Event>;
  onlineStatus: Observable<Event>;
  sidenavSbj: Subject<any> = new Subject();
  ws: Subject<any>;

  private _refreshing_time = 5000; // in milliseconds
  private _timeWindow: TimeWindow = new TimeWindow();
  private _timeWindowSbj = new Subject<TimeWindow>();
  private _tokenSbj = new Subject<string>();
  private now: moment.Moment;
  private tsSbj = new Subject<moment.Moment>();
  private version = VERSION;

  constructor(
    private logger: NGXLogger,
    private snackBar: MatSnackBar,
    private storage: StorageService,
    private translateService: TranslateService,
    private userService: UserService
  ) {
    if (environment.refreshing_time) {
      const rt = +environment.refreshing_time.slice(0, -1);
      switch (
        environment.refreshing_time.charAt(
          environment.refreshing_time.length - 1
        )
      ) {
        case 'm':
          this._refreshing_time = rt * 60000;
          break;
        case 's':
        default:
          this._refreshing_time = rt * 1000;
          break;
      }
      this.onlineStatus = observableFromEvent(window, 'online');
      this.offlineStatus = observableFromEvent(window, 'offline');
    }

    interval(this._refreshing_time).subscribe(
      (x) => this.updateTime(),
      (e) => this.handleError<any>('CubeService interval: ' + e.message)
    );
    interval(this._refreshing_time).subscribe(
      (x) => this.setTimeSlider(),
      (e) => this.handleError<any>('CubeService interval: ' + e.message)
    );

    this.setTimeSlider();
    this.updateTime();
  }

  cleanCache(): void {
    this.storage.deleteFromStorage(`${environment.localStorageKeyPrefix}_role`);
    this.storage.deleteFromStorage(
      `${environment.localStorageKeyPrefix}_tenant`
    );
  }

  formatSize(bytes: number, decimalPoints: number = 3): string {
    if (bytes === 0) {
      return '0 B';
    }
    const k = 1000;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / k ** i).toFixed(decimalPoints))} ${sizes[i]}`;
  }

  getTimeWindow(): TimeWindow {
    return this._timeWindow;
  }

  getTS(): moment.Moment {
    return this.now;
  }

  getTSObservable(): Observable<moment.Moment> {
    return this.tsSbj.asObservable();
  }

  getUserMenu(): any {
    const myMenu = [];

    Object.keys(MODULES).forEach((page: string) => {
      const module = { ...MODULES[page] } as any;
      if (module.menu) {
        if (module.menu.sub) {
          module.menu.sub = module.menu.sub.filter((subMenuItem) =>
            MODULES[subMenuItem.key]
              ? this.userService.hasTenantModules(
                  MODULES[subMenuItem.key].roles
                )
              : false
          );
        }
        myMenu.push(module.menu);
      }
    });

    TENANT_CONFIG.settings.externalPages?.forEach((page: any) => {
      if (this.userService.hasTenantModules(page.roles)) {
        myMenu.push({
          name: page.name,
          mat_icon: page.icon,
          open: false,
          external: true,
          link: page.link,
        });
      }
    });
    return myMenu;
  }

  getVersion(): string {
    return this.version.version + '-' + this.version.hash;
  }

  setTimeSlider(from?: moment.Moment, to?: moment.Moment) {
    this._timeWindow.now = moment().utc();
    if (!environment.production) {
      this._timeWindow.now.subtract(1, 'years');
    }
    this._timeWindow.from =
      from || moment(this._timeWindow.now).subtract(7, 'days');
    this._timeWindow.to = to || moment(this._timeWindow.now);
    this._timeWindowSbj.next(this._timeWindow);
  }

  showMessage(type: MessageType, msg: string): void {
    const snackBarConfig = new MatSnackBarConfig();
    switch (type) {
      case MessageType.Info:
        snackBarConfig.duration = environment.snackbar_duration;
        snackBarConfig.panelClass = ['snackBarMessage', 'infoMessage'];
        break;
      case MessageType.Error:
        snackBarConfig.duration = environment.snackbar_error_duration;
        snackBarConfig.panelClass = ['snackBarMessage', 'errorMessage'];
        break;
    }

    this.snackBar.open(
      this.translateService.instant(msg),
      'close',
      snackBarConfig
    );
  }

  timeWindow(): Observable<TimeWindow> {
    return this._timeWindowSbj.asObservable();
  }

  token(): Observable<String> {
    return this._tokenSbj.asObservable();
  }

  private updateTime() {
    this.now = moment().utc();
    this.tsSbj.next(this.now);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error.message);

      // TODO: better job of transforming error for user consumption
      this.logger.error(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
