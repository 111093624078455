import { globalEnvironment } from './global';
import { BASE_URL } from './commons/base-url';
import { AUDITOR_COPILOT_COMMON_ENV } from './commons/auditor-copilot';
import { MODULES } from './module.config';

export const environment = {
  ...globalEnvironment,
  ...BASE_URL,
  ...AUDITOR_COPILOT_COMMON_ENV,
  admin_tool_url: 'https://gw-dev.cube.rina.org/admin-tool',
  routes: [MODULES.inspections.route],
  api: {
    ...globalEnvironment.api,
  },
  b2cPolicies: {
    clientId: '83405386-3e40-4526-b3f0-34f63eff8b3a',
    tenantId: 'c7788286-49f4-40db-bf26-fc1c677df787',
    redirectUri: 'https://auditor-copilot-dev.cube.rina.org/',
    redirectCourtesyPageUri: 'https://cube.rina.org/',
    postLogoutRedirectUri: 'https://cube.rina.org/',
    names: {
      resetPassword: 'B2C_1A_PASSWORDRESET',
      signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
    },
    authorities: {
      signUpSignIn: {
        authority:
          'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN',
      },
      resetPassword: {
        authority:
          'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com/B2C_1A_PASSWORDRESET',
      },
      editProfile: {
        authority:
          'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com/B2C_1A_PROFILEEDIT',
      },
    },
    domain: 'https://test-login.rina.org/',
    subdomain: 'rinagroupb2cdevl.onmicrosoft.com',
    authorityDomain:
      'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com',
  },
  pageOptions: {
    pageSize: 10,
    pageSizeOptions: [5, 10, 25, 100],
    showFirstLastButtons: true,
  },
  adminToolDomain: ['@rina.org'],
  localStorageKeyPrefix: 'cube-auditor-copilot-dev',
};
