import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

import { Observable } from 'rxjs';
@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const active_tenant = localStorage.getItem(
      `${environment.localStorageKeyPrefix}_tenant`
    );

    let headers: HttpHeaders = req.headers;

    headers = req.headers.append('x-solution', environment.solution);

    if (active_tenant) {
      headers = headers.append('x-company', active_tenant);
    }

    const clonedReq = req.clone({ headers });

    return next.handle(clonedReq);
  }
}
