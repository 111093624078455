import { SystemActions } from '../actions/system.actions';
import { SystemState } from '../states/system.state';

import { createReducer, on } from '@ngrx/store';

const INIT_STATE: SystemState = {
  theme: 'light',
};

export const systemReducers = createReducer(
  INIT_STATE,
  on(
    SystemActions.ChangeTheme,
    (state: SystemState): SystemState => ({
      ...state,
      theme: state.theme === 'dark' ? 'light' : 'dark',
    })
  )
);
