// Sidebar menu items are shown in this order
export const MODULES = {
  inspections: {
    roles: ['InspectionIngestion'],
    route: {
      path: 'inspections',
      loadChildren: () =>
        import('../app/pages/inspections/inspections.module').then(
          (m) => m.InspectionsModule
        ),
      data: { module: 'inspections' },
    },
    menu: {
      name: 'side_menu.inspection_list',
      mat_icon: 'search',
      open: false,
      link: 'inspections',
    },
  },
};
