import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({ selector: 'cube-activable-card-group' })
export class ActivableCardGroupDirective implements AfterViewInit {
  @Input() multiple = false;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    if (!this.multiple) {
      Array.from(this.el.nativeElement.querySelectorAll('input')).forEach((value: HTMLInputElement, index) => {
        value.type = 'radio';
        value.name = 'radio';
      });
    }
  }
}
