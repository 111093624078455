import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpinnerComponent } from './components/spinner/spinner.component';
import { ValidationErrorComponent } from './components/validation-error/validation-error.component';
import { CoreModule } from '../core/core.module';
import * as echarts from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';

@NgModule({
  declarations: [SpinnerComponent, ValidationErrorComponent],
  imports: [
    CommonModule,
    CoreModule,
    NgxEchartsModule.forRoot({
      echarts,
    }),
  ],
  exports: [NgxEchartsModule, SpinnerComponent, ValidationErrorComponent],
})
export class SharedModule {}
