<label class="activable-card-container">
  <input
          (click)="_onInputClick($event)"
          [attr.name]="name"
          [attr.value]="value"
          [checked]="checked"
          [disabled]="disabled"
          class="inputField"
          type="checkbox"
  />
  <span class="activable-card-object">
    <span class="activable-card-label">{{ label }}</span>
    <ng-content></ng-content>
  </span>
</label>
