import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DynamicDirective } from './dynamic-directive/dynamic.directive';

const directives = [DynamicDirective];

@NgModule({
  declarations: directives,
  imports: [CommonModule],
  exports: directives,
})
export class DirectivesModule {}
