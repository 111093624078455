import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

import { Observable } from 'rxjs';
@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const active_tenant = localStorage.getItem(
      `${environment.localStorageKeyPrefix}_tenant`
    );

    if (active_tenant) {
      return next.handle(
        req.clone({
          headers: req.headers
            .set('x-company', active_tenant)
            .set('x-solution', environment.solution),
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
