export interface EnvironmentConfiguration {
  settings: any;
  company_asset_table?: any;
  filter_layout?: any;
  kpi_status?: any;
  measures_groups?: any[];
}

export enum Solutions {
  AUDITOR_COPILOT = 'auditor_copilot',
}
