import { Component, OnInit } from '@angular/core';
import * as screenfull from 'screenfull';
import { Screenfull } from 'screenfull';

// declare var screenfull: any = require('screenfull');

@Component({
  selector: 'cube-fullscreen',
  templateUrl: './fullscreen.component.html',
  styleUrls: ['./fullscreen.component.scss'],
})
export class FullscreenComponent implements OnInit {
  isFullscreen = false;
  screen = screenfull as Screenfull;

  constructor() {}

  ngOnInit() {}

  toggleFullscreen() {
    if (this.screen.isEnabled) {
      this.screen.toggle();
      this.isFullscreen = !this.isFullscreen;
    }
  }
}
