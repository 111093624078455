import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';

export enum Errors {
  required = 'required',
  minLength = 'minlength',
  maxLength = 'maxlength',
  invalidName = 'invalidname',
  alreadyExists = 'alreadyexists',
  invalidNumber = 'invalidnumber',
  positiveNumber = 'positivenumber',
  invalidDecimalPlaces = 'decimalplaces',
  notANumber = 'notanumber',
  min = 'min',
  max = 'max',
  pattern = 'pattern',
}

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  readonly emailRegex = /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/;

  public getMessage(
    errorName: string,
    errorValue: any,
    fieldName: string
  ): [string, Record<string, any> | null] {
    const minmax =
      fieldName === 't' || fieldName === 'threshold'
        ? `, ${fieldName} Min must be less than ${fieldName} Max`
        : ``;
    const translationKey = 'validation';

    const errorMessages = {
      [Errors.alreadyExists]: [`${translationKey}.alreadyExists`],
      [Errors.invalidDecimalPlaces]: [
        `${translationKey}.invalidDecimalPlaces`,
        { required: errorValue.required },
      ],
      [Errors.invalidName]: [`${translationKey}.invalidName`],
      [Errors.invalidNumber]: [`${translationKey}.invalidNumber`],
      [Errors.maxLength]: [
        `${translationKey}.maxLength`,
        { requiredLength: errorValue.requiredLength },
      ],
      [Errors.notANumber]: [`${translationKey}.notANumber`],
      [Errors.pattern]: [`${translationKey}.pattern`, { fieldName }],
      [Errors.positiveNumber]: [`${translationKey}.positiveNumber`],
      [Errors.required]: [`${translationKey}.required`],
      // [Errors.min]: `Field must be between ${ValidatorConst[fieldName + 'Min']} and
      //   ${ValidatorConst[fieldName + 'Max']}`,
      // [Errors.max]: `Field must be between ${ValidatorConst[fieldName + 'Min']} and
      //   ${ValidatorConst[fieldName + 'Max']}`,
      // [Errors.pattern]: `Field must be in cammel case`,
    };
    return errorMessages[errorName];
  }

  public getDecimalRegex(decimals: number): RegExp {
    return new RegExp(`^-?\\d+(\\.\\d{1,${decimals}})?$`);
  }

  public regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value == null) {
        return null;
      }
      return regex.test(control.value) ? null : error;
    };
  }
}
