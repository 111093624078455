<div class="toolbar-user-container">
  <button
    *ngIf="!user"
    mat-button
    class="toolbar-user-btn"
    [ngStyle.xs]="{ 'min-width': '70px' }"
  >
    <span fxLayout="row" fxLayoutAlign="start center">
      <img class="avatar" src="./assets/images/avatars/noavatar.png" />
      <span class="name" fxHide fxShow.gt-xs>Log In</span>
    </span>
  </button>
  <button
    *ngIf="user"
    mat-button
    (click)="isOpen = !isOpen"
    class="toolbar-user-btn"
    [class.open]="isOpen"
    [ngStyle.xs]="{ 'min-width': '70px' }"
  >
    <div fxLayout="row" fxLayoutAlign="center center">
      <img class="avatar" src="./assets/profile.jpg" />
      <div
        class="name"
        fxLayoutAlign="center center"
        fxLayout="column"
        fxHide
        fxShow.gt-xs
      >
        {{ user?.given_name }}
        <cite
          fxLayoutAlign="center center"
          fxHide
          fxShow.gt-xs
          class="active-role"
          >{{ activeTenant | uppercase }} - {{ activeRole }}</cite
        >
      </div>
      <mat-icon class="icon" fxHide fxShow.gt-xs>keyboard_arrow_down</mat-icon>
    </div>
  </button>
  <div *ngIf="user" class="dropdown mat-elevation-z1" [class.open]="isOpen">
    <div class="content">
      <mat-nav-list>
        <!-- Tenant List -->
        <mat-list-item (click)="changeTenant()" *ngIf="enableTenantChange">
          <a matLine>Tenant List</a>
          <button mat-icon-button>
            <mat-icon>language</mat-icon>
          </button>
        </mat-list-item>

        <!-- Roles -->
        <mat-list-item (click)="changeProfile()" *ngIf="user?.roles.length > 1">
          <a matLine>Roles</a>
          <button mat-icon-button>
            <mat-icon>compare_arrows</mat-icon>
          </button>
        </mat-list-item>

        <!-- Support -->
        <mat-list-item (click)="openSupportPage()">
          <a matLine>{{ "menu.support" | translate }}</a>
          <button mat-icon-button>
            <mat-icon>headset_mic</mat-icon>
          </button>
        </mat-list-item>

        <mat-divider></mat-divider>

        <!-- Change Theme -->
        <mat-list-item (click)="changeTheme()">
          <a matLine>{{ "menu.change_theme" | translate }}</a>
          <button mat-icon-button>
            <mat-icon>invert_colors</mat-icon>
          </button>
        </mat-list-item>

        <mat-divider></mat-divider>

        <!-- Logout -->
        <mat-list-item (click)="doLogout()">
          <a matLine>{{ "menu.logout" | translate }}</a>
          <button mat-icon-button>
            <mat-icon>exit_to_app</mat-icon>
          </button>
        </mat-list-item>
      </mat-nav-list>
    </div>
  </div>
</div>
