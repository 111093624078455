import { Directive, OnDestroy } from '@angular/core';

import { TokenDetails } from '../models/auth/token-details.type';
import { AuthActions } from '../store/actions/auth.actions';
import { AuthenticationService } from '../services/authentication.service';
import { AppState } from '../store/states/app.state';
import {
  selectTokenDetails,
  selectUserTenants,
} from '../store/selectors/auth.selectors';

import { MsalBroadcastService } from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Directive({ selector: '[cubeMsalFlow]' })
export class CubeMSALFlowDirective implements OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  constructor(
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly store: Store<AppState>,
    private readonly authService: AuthenticationService
  ) {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: () => {
          this.store.dispatch(AuthActions.setActiveAccount());
        },
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((message: EventMessage) =>
          (
            [
              EventType.LOGIN_SUCCESS,
              EventType.ACQUIRE_TOKEN_SUCCESS,
            ] as Array<EventType>
          ).includes(message.eventType)
        ),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (message: EventMessage) => {
          const tokenDetails = message.payload as unknown as TokenDetails;
          this.store.dispatch(AuthActions.setTokenDetails({ tokenDetails }));
        },
      });

    this.store
      .select(selectTokenDetails)
      .pipe(
        filter(
          (
            tokenDetails: TokenDetails | undefined
          ): tokenDetails is TokenDetails => !!tokenDetails
        ),
        concatLatestFrom(() => this.store.select(selectUserTenants)),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: ([tokenDetails, tenants]) => {
          if (!tenants.length) {
            this.store.dispatch(
              AuthActions.getUserTenants({ uniqueId: tokenDetails.uniqueId })
            );
          }
        },
      });
  }

  ngOnInit(): void {
    // this.store.dispatch(AuthActions.RedirectToGateway());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
