import { Component, OnInit } from '@angular/core';

import { Theme } from '../../models/cube/theme.type';
import { SystemActions } from '../../store/actions/system.actions';
import { selectTheme } from '../../store/selectors/system.selectors';

import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cube-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  isDarkTheme: boolean;
  today: number = Date.now();

  constructor(private store: Store) {}

  ngOnInit() {
    this.store
      .select<Theme>(selectTheme)
      .pipe(map((theme: Theme): boolean => theme === 'dark'))
      .subscribe({
        next: (isDark: boolean) => {
          this.isDarkTheme = isDark;
        },
      });
  }

  toggleDarkTheme() {
    this.store.dispatch(SystemActions.ChangeTheme());
  }
}
