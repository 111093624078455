import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { VERSION } from '../../../environments/version';
import { CubeService } from '../../services/cube/cube.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cube-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SidemenuComponent implements OnInit, OnDestroy {
  @Input() menus: any[] = [];
  @Input() iconOnly = false;

  isDarkTheme: boolean;
  version = VERSION;

  protected _destroy$: Subject<any> = new Subject<any>();

  constructor(private cubeService: CubeService) {}

  ngOnInit() {
    this.menus = this.cubeService.getUserMenu();
    this.cubeService.changeCubeMenu$
      .pipe(takeUntil(this._destroy$))
      .subscribe((s) => {
        this.menus = this.cubeService.getUserMenu();
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
