import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { CubeService, MessageType } from '../cube/cube.service';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private cubeService: CubeService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next
      .handle(request)
      .pipe(catchError((err) => this.handleError(request, err)));
  }

  private handleError(
    request: HttpRequest<unknown>,
    httpError: HttpErrorResponse
  ) {
    const errorMessage = httpError.error?.error ?? environment.messages.error;
    this.cubeService.showMessage(MessageType.Error, errorMessage);

    return throwError(() => httpError);
  }
}
