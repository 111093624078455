import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import {
  Tenant,
  ProfileFeature,
  ProfileRole,
} from '../../app/models/auth/admin-tool.type';
import { environment } from '../../environments/environment';
import { selectTokenDetails } from '../store/selectors/auth.selectors';

import { MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private featureJsonUrl = 'assets/json/features.json';
  private readonly http: HttpClient = inject(HttpClient);
  private readonly msalService: MsalService = inject(MsalService);
  private roleJsonUrl = 'assets/json/role.json';
  private tenantJsonUrl = 'assets/json/tenant.json';

  constructor(private store: Store) {}

  // Admin tool API services
  tenants(uniqueId: string): Observable<Array<Tenant>> {
    return this.http.get<Array<Tenant>>(
      this.checkEmailDomain()
        ? this.tenantJsonUrl
        : `${environment.ADMIN_TOOL_URL}/users/${uniqueId}/solutions/${environment.solution}/tenants`
    );
  }

  roles(uniqueId: string, tenant: string): Observable<Array<ProfileRole>> {
    return this.http.get<Array<ProfileRole>>(
      this.checkEmailDomain()
        ? this.roleJsonUrl
        : `${environment.ADMIN_TOOL_URL}/tenants/${tenant}/users/${uniqueId}/platforms/CUBE/solutions/${environment.solution}/roles`
    );
  }

  features(role: string, tenant: string): Observable<Array<ProfileFeature>> {
    return this.http.get<Array<ProfileFeature>>(
      this.checkEmailDomain()
        ? this.featureJsonUrl
        : `${environment.ADMIN_TOOL_URL}/tenants/${tenant}/roles/${role}/platforms/CUBE/solutions/${environment.solution}/features`
    );
  }

  login() {
    this.msalService.loginRedirect();
  }

  logout() {
    this.msalService.logoutRedirect();
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.msalService.instance.getActiveAccount();
    const allAccounts = this.msalService.instance.getAllAccounts();
    if (!activeAccount && allAccounts.length > 0) {
      this.msalService.instance.setActiveAccount(allAccounts[0]);
    }
  }

  checkEmailDomain(): boolean {
    let email: string = '';
    this.store.select(selectTokenDetails).subscribe((tokenDetails) => {
      email = tokenDetails.idTokenClaims.email;
    });
    return email
      ? environment.adminToolDomain.some((domain) => email.endsWith(domain))
      : false;
  }
}
