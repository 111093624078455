import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mathRound',
})
export class MathRoundPipe implements PipeTransform {
  /**
   * round up a decimal value
   * @param value decimal value
   * @param digits decimals
   */

  transform(value: number, digits: number): any {
    if (!value) {
      return 'n.a.';
    }
    if (!digits) {
      return value;
    }
    const fractionDigits = 10 ** digits;
    const roundedValue = Math.round(value * fractionDigits) / fractionDigits;
    return roundedValue;
  }
}
