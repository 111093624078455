import { Injectable } from '@angular/core';

import { selectUserDetails } from '../../store/selectors/auth.selectors';

import { Store } from '@ngrx/store';
import { intersection } from 'lodash';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  filteredUserTenantsList: string[] = [];
  onDepartmentChange: Subject<string> = new Subject();
  userTenantsList: string[] = [];

  private _user: any = null;
  private _role: string;

  constructor(private store: Store) {
    this.store.select(selectUserDetails).subscribe((res) => {
      this._user = res;
    });
  }

  hasRole(roles: string | string[]): boolean {
    roles = [].concat(roles);
    return (
      roles.includes('*') ||
      this._user.roles.map((m) => m.name).some((r) => roles.includes(r))
    );
  }

  hasTenantModules(
    featureName: string | string[],
    role: string = this._role
  ): boolean {
    const features = [].concat(featureName);
    let has = false;
    const roleModulesFeatures = role
      ? [].concat(this._user.rolesAndModules.find((r) => r.name === role))
      : this._user.rolesAndModules;
    roleModulesFeatures.forEach((modulesList) => {
      modulesList.roleModules.forEach((module) => {
        if (
          !!intersection(
            features,
            module.map((f) => f.name)
          ).length
        ) {
          has = true;
        }
      });
    });
    return has;
  }

  // isLoggedIn(): boolean {
  //   return this._user != null;
  // }

  // setActiveRole(role): void {
  //   localStorage.setItem(STORAGE_KEYS.activeRole, role);
  //   this._role = role;
  //   this.onDepartmentChange.next(role);
  // }

  // setUser(user: User): void {
  //   this._user = user;
  // }

  // updateToken(token: string): void {
  //   this._user.token = token;
  // }
}
