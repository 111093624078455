import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { environment } from '../../../environments/environment';
import { selectUserDetails } from '../../store/selectors/auth.selectors';

import { Store } from '@ngrx/store';

@Component({
  selector: 'cube-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @Output() onDepartmentClick: EventEmitter<any> = new EventEmitter<any>();

  @Input() drawer;
  @Input() matDrawerShow;
  @Input() sidebar;
  @Input() sidenav;

  currentUser?: any = null;
  searchOpen = false;
  isSupportToolEnabled = true;
  isSettingPanelEnabled = false;

  constructor(private store: Store) {}

  ngOnInit() {
    this.currentUser = this.store.select(selectUserDetails);
  }

  ngOnDestroy(): void {}

  onAction(action: any) {
    // console.log(action);
  }

  onSearch(search: any) {}

  openSupportPage() {
    window.open(environment.supportUrl, '_blank');
  }
}
